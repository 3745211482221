import Vue from "vue"
import axios from "axios"
import store from "@state/store"

Vue.prototype.$axios = axios

const API_URL = store.getters["env/apiUrl"]
axios.defaults.baseURL = API_URL
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.xsrfHeaderName = "X-CSRF-Token"
axios.defaults.withCredentials = true
axios.defaults.timeout = 60000

axios.interceptors.request.use(
  function (config) {
    // CSRF Token
    config.headers["X-CSRF-Token"] = store.getters["auth/csrfToken"]

    // Share Token
    if (store.getters["auth/shareToken"]) {
      if (_.isObject(config.params)) {
        config.params.share_token = store.getters["auth/shareToken"]
      } else {
        config.params = { share_token: store.getters["auth/shareToken"] }
      }
    }
    return config
  },
  function (error) {
    console.error("axios config error", error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return new Promise((resolve, reject) => {
      store.dispatch("app/handleAxiosErrorResponse", error).finally(() => {
        reject(error)
      })
    })
  }
)
