import Vue from "vue"
import store from "@state/store"
import router from "@router"

import * as Sentry from "@sentry/vue"

// Sentry - error and performance tracking
// https://docs.sentry.io

const sentryEnvironment = process.env.VUE_APP_SENTRY_ENVIRONMENT

if (sentryEnvironment) {
  Sentry.init({
    Vue,
    environment: sentryEnvironment,
    dsn: "https://73373a7657694a838d6250126cea4ac9@sentry.io/1381554",
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    tracePropagationTargets: [
      "dev.app.kitesrm.com",
      "stage.app.kitesrm.com",
      "app.kitesrm.com",
    ],
    tracesSampleRate: 0.1,
    autoSessionTracking: true,
    trackComponents: true,
    ignoreErrors: [
      "UnhandledRejection",
      "NavigationDuplicated",
      "ChunkLoadError",
      "t: Rendering cancelled, page 1",
      "ResizeObserver loop limit exceeded",
      "Request failed with status code 401",
      "Request failed with status code 404",
      "Request failed with status code 406",
      "Request failed with status code 422",
      "Request failed with status code 440",
      "timeout of 60000ms exceeded",
      "Network Error",
      "Request aborted",
      /via a navigation guard$/,
    ],
  })
}

const initSentrySession = () => {
  if (sentryEnvironment && Sentry && store && store.getters) {
    const activeUser = store.getters["auth/activeUser"]
    const activeGroup = store.getters["auth/activeGroup"]

    if (activeUser && activeGroup) {
      Sentry.setUser({
        id: activeUser.id,
        name: activeUser.name,
        email: activeUser.email,
        activeGroupId: activeGroup.id,
      })
    }
  }
}

export { initSentrySession }
