import Vue from "vue"
import Vuetify from "vuetify/lib"
import { i18n } from "@plugins/i18n"

// Custom Icons
import reportIconSvg from "@components/icons/report"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        // primary: "#2196F3",
        // accent: "#FF4081",
        // secondary: "#30b1dc",
        // success: "#4CAF50",
        // info: "#2196F3",
        // warning: "#FB8C00",
        // error: "#FF5252"
      },
      light: {
        primary: "#1C1836",
        accent: "#e91e63",
        secondary: "#30b1dc",
        // success: "#4CAF50",
        // info: "#2196F3",
        warning: "#DE7974",
        // error: "#FF5252",
      },
    },
  },
  icons: {
    // Material Icons - https://fonts.google.com/icons
    iconfont: "md",
    // Custom Icons - @components/icons/...
    values: {
      report: {
        component: reportIconSvg,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 960,
      lg: 1264,
      xl: 1904,
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  options: {
    minifyTheme: function (css) {
      return process.env.NODE_ENV === "production"
        ? css.replace(/[\s|\r\n|\r|\n]/g, "")
        : css
    },
  },
})
