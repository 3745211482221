import Vue from "vue"

const { marked } = require("marked")
const renderer = new marked.Renderer()
const linkRenderer = renderer.link

renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text)
  return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ')
}

marked.setOptions({
  renderer: renderer,
  baseUrl: "https://",
  smartypants: true,
})

Vue.prototype.$marked = marked
