import Vue from "vue"
import VueI18n from "vue-i18n"
import { messages, dateTimeFormats, numberFormats } from "@utils/lang/en"
import axios from "axios"

// ===
// Vue i18n API
// https://kazupon.github.io/vue-i18n/api/
// ===

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  dateTimeFormats,
  numberFormats,
  silentTranslationWarn: true,
})

// ===
// Lazy Load Translations
// ===

const loadedLanguages = ["en"] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common["Accept-Language"] = lang
  document.querySelector("html").setAttribute("lang", lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(
        /* webpackChunkName: "lang-[request]" */
        `@utils/lang/${lang}`
      ).then((msgs) => {
        i18n.setLocaleMessage(lang, msgs.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
