import Vue from "vue"
import VueLodash from "vue-lodash"
import VueNumerals from "vue-numerals"
import Vuelidate from "vuelidate"
import lodash from "lodash"

// HighCharts
import HighchartsVue from "highcharts-vue"
import Highcharts from "highcharts"
import Sunburst from "highcharts/modules/sunburst.js"
import HighchartsNoData from "highcharts/modules/no-data-to-display"
import loadExporting from "highcharts/modules/exporting"

HighchartsNoData(Highcharts)
Highcharts.setOptions({
  lang: {
    noData:
      "<p style='font-size: 18px; text-align: center; line-height: 16px;'>No field data is currently associated with this graph.</p> <p style='font-size: 16px; text-align: center; line-height: 16px;'>Once a value exists this graph will automatically update</p>",
  },
  noData: {
    useHTML: true,
    style: {
      fontWeight: "normal",
      whiteSpace: "normal",
      wordWrap: "break-word",
      color: "#999999",
      width: window.innerWidth > 600 ? "500px" : "200px",
    },
  },
})
Sunburst(Highcharts)
loadExporting(Highcharts)

// ---
// Register Helpers
// ---
Vue.prototype.$log = console.log
Vue.prototype.$highcharts = Highcharts

// ---
// Register Vue Shortkey
// ---
// div.v-input:not(.quick-actions-search-input) input
Vue.use(require("vue-shortkey"), {
  prevent: [
    ".ProseMirror",
    "div.v-input:not(.quick-actions-search-input, .add-widget-search-input, .container-search-input) input",
    "textarea",
  ],
})

// ---
// Register Simple Plugins - create file if you need complex config.
// ---
Vue.use(Vuelidate)
Vue.use(VueLodash, { name: "custom", lodash: lodash })
Vue.use(VueNumerals, { locale: "en" })
Vue.use(HighchartsVue, { Highcharts })

// -----------------------------
// Usage Notes
// -----------------------------

// ---
// NumeralJS - https://github.com/Kocal/vue-numerals
// ---
// Note: we should consider setting the local on init of app
// based on browser configs to display correct currency code.
//
// Usage in template:
// Count (prop): 12345
//
// {{ count | numeralFormat }}
// returns 12,345
//
// {{ count | numeralFormat('$0,0[.]00') }}
// return $12,345

// ---
// Lodash
// ---
// Usage in .Vue files: Global access to full build.
// _.toInteger()
