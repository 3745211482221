import amplitude from "amplitude-js"
const amplitudeEnv = process.env.VUE_APP_AMPLITUDE_ENVIRONMENT

const initAmplitude = (userProperties) => {
  if (typeof window === "undefined" || !amplitudeEnv) {
    return
  }

  amplitude
    .getInstance()
    .init(amplitudeEnv, _.get(userProperties, "id", null), {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      batchEvents: true,
    })

  if (userProperties) {
    amplitude.getInstance().setUserProperties(userProperties)
  } else {
    amplitude.getInstance().clearUserProperties()
  }
}

export { amplitude, amplitudeEnv, initAmplitude }
