import Vue from "vue"
import IdleVue from "idle-vue"
import App from "./app"
import router from "@router"
import store from "@state/store"
import "@plugins"
import "@components/_globals"
import { initAmplitude } from "@plugins/amplitude"
import wb from "./registerServiceWorker"
import vuetify from "./plugins/vuetify"

Vue.prototype.$workbox = wb

Vue.config.productionTip = process.env.NODE_ENV === "production"
Vue.config.performance = process.env.NODE_ENV !== "production"

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000, // 5 Minutes
})

new Vue({
  router,
  store,
  vuetify,
  mounted: function () {
    initAmplitude()
  },
  render: (h) => h(App),
  onIdle() {
    this.$store.dispatch("sessionTimeout/initSessionTimeoutCountdown", {
      activeGroup: this.$store.getters["auth/activeGroup"],
      callback: () => {
        setTimeout(() => {
          this.$store.dispatch("sessionTimeout/setTimeoutModalActive", true)
          this.$store.dispatch("app/setActiveDialog", {
            title: "Are you still there?",
            dialog: "activeModal",
            action: "toggleDialog",
            context: "SessionTimeoutModal",
            meta: {},
          })
        }, 1000)
      },
    })
  },
  onActive() {
    this.$store.dispatch("sessionTimeout/clearSessionTimeoutCountdown")

    // It's not good enough to just clear the timeout context client side, we
    // also must let the server know to keep the session active.
    this.$store.dispatch("sessionTimeout/pingServer", {
      activeGroup: this.$store.getters["auth/activeGroup"],
      activeUser: this.$store.getters["auth/activeUser"],
    })
  },
}).$mount("#app")
