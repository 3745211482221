import Vue from "vue"
import VueRouter from "vue-router"
import VueMeta from "vue-meta"
import NProgress from "nprogress"
import store from "@state/store"
import routes from "./routes"
import get from "lodash/get"

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: "page",
})

const router = new VueRouter({
  mode: "history",
  routes: routes,
  base: "/",
  scrollBehavior(routeTo, routeFrom, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (routeFrom.name !== null) {
    NProgress.start()
  }

  // Set route cache.
  store.dispatch("app/setLastPath", routeFrom.path)
  store.dispatch("app/setCurrentPath", routeTo.path)
  cleanupAppDialogs(routeTo, routeFrom)

  // Validate share token.
  if (routeTo.params.secret && routeTo.name != "set_access_code") {
    return store
      .dispatch("auth/validateShareToken", routeTo.params.secret)
      .then((isValid) => {
        if (isValid === true) {
          next()
        } else {
          let error = _.first(_.get(isValid, "response.data.errors"))
          if (error && error.code === 123) {
            window.location = `/share/${routeTo.params.secret}/access-code`
          } else {
            redirectToSignIn(routeTo, routeFrom, next)
          }
        }
      })
  }

  // Return if routeTo an unauthenticated page.
  const skipAuth = routeTo.matched.some((route) => route.meta.skipAuth)

  if (skipAuth) {
    return next()
  }

  // Validate session and handle transition. Errors from validate failing
  // are caught from the Axios interceptors via "app/handleAxiosErrorResponse".
  // Includes 503, 401, 440, etc.
  return store
    .dispatch("auth/validate")
    .then((sessionObject) => {
      sessionObject
        ? handleTransition(routeTo, routeFrom, next, sessionObject, store)
        : redirectToSignIn(routeTo, routeFrom, next)
    })
    .catch((e) => {
      // Fallback to signin, just in case.
      redirectToSignIn(routeTo, routeFrom, next)
    })
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              if (
                get(routeFrom, "name") &&
                get(routeFrom, "name") === get(args, "[0].name")
              ) {
                NProgress.done()
              }
              next(...args)
              reject(new Error("Redirected"))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }
  next()
})

// When each route is finished evaluating...
// eslint-disable-next-line no-unused-vars
router.afterEach((routeTo, routeFrom) => {
  if (
    !_.get(routeTo, "meta.skipAuth", false) &&
    !_.get(routeTo, "params.secret", null)
  ) {
    store.dispatch("app/routeReplaceState")
  }

  NProgress.done()
})

export default router

// ---
// Private helpers
// ---

function redirectToSignIn(routeTo, routeFrom, next) {
  const routeObject = { name: "signin" }

  if (routeTo.fullPath !== "/") {
    _.set(routeObject, "query.redirectFrom", routeTo.fullPath)
  }

  if (_.get(routeTo, "query.auth")) {
    _.set(routeObject, "query.auth", routeTo.query.auth)
  }

  next(routeObject)
}

function handleTransition(routeTo, routeFrom, next, sessionObject, store) {
  // ===
  // Case 1 - User has Privacy Consent
  // ===
  const hasConsented =
    _.get(sessionObject, "users[0].privacy_consent_date") || null

  if (!hasConsented && routeTo.name !== "privacy-consent") {
    next({
      name: "privacy-consent",
      query: {
        redirectFrom: routeTo.fullPath,
        isNewSsoUser: routeTo.query["new-sso-user"],
      },
    })
  } else {
    // Check for onboarding route query param
    if (routeTo.query.onboarding) {
      store.dispatch("app/toggleOnboardingDelayed")
    }
    // Check for notifications route query param
    if (routeTo.query.notifications) {
      store.dispatch("app/toggleNotificationsDelayed")
    }

    // Run app load callbacks - this runs only 1 time on app load, and only for most routes with static exceptions
    const skipInitCallbacks = routeTo.matched.some((route) => route.meta.skipInitCallbacks)
    if(!skipInitCallbacks && !store.getters["app/appInitCallbacksDone"]) {
      store.dispatch("app/trapBackNavigation")
      store.dispatch("app/appInitCallbacksDone")
      if(routeTo.name == "404") {
        return next("/")
      }
    }
    next()
  }
}

function cleanupAppDialogs(routeTo, routeFrom) {
  const sameRoute = _.get(routeTo, "name") === _.get(routeFrom, "name")
  const samePermalink =
    _.get(routeTo, "params.permalink") === _.get(routeFrom, "params.permalink")

  if (!sameRoute || !samePermalink) {
    store.dispatch("app/closeAllActiveDialogs")
  }
}
