import store from "@state/store"

const isProd = process.env.NODE_ENV === "production"

const initStonlySession = () => {
  if (isProd && StonlyWidget && store && store.getters) {
    const activeUser = store.getters["auth/activeUser"]
    const activeGroup = store.getters["auth/activeGroup"]
    const activeNetwork = store.getters["auth/activeNetwork"]

    if (activeUser && activeGroup && activeNetwork) {
      StonlyWidget("identify", `${activeUser.id}`, {
        name: activeUser.name,
        email: activeUser.email,
        "user-id": activeUser.id,
        "group-id": activeGroup.id,
        "network-id": activeNetwork.id,
      })
    }
  }
}

const stonlyTrackEvent = (eventName, params) => {
  if (isProd && StonlyWidget && eventName) {
    StonlyWidget("track", eventName, params)
  }
}

export { initStonlySession, stonlyTrackEvent }
